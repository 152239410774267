import { MEAT_CATEGORIES, VolumeUnits } from "../resources";
import { MultipleProductInfo } from "../types";

export function filterTitle(product: MultipleProductInfo) {
  const {title, volume, multipleCount} = product
  const filteredTitle = title.toLowerCase().replace(String(volume), '').trim()
  const withoutUnit = filteredTitle.split(' ').slice(0, -1).join(' ').trim()
  let withoutPerAvg = withoutUnit.replace(' per', '').replace(' avg', '')
  if(multipleCount > 1) withoutPerAvg += ` x ${multipleCount}`
  return withoutPerAvg
}

export function roundNonZero(value: number) {
  if (value >= 1) {
    // For values greater than or equal to 1, round to 2 decimal places
    return Math.round(value * 100) / 100;
} else if (value > 0 && value < 1) {
    // For values less than 1, round the first non-zero digit
    const decimalStr = value.toString();
    const decimalIndex = decimalStr.indexOf('.') + 1;

    // Find the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (decimalStr[firstNonZeroIndex] === '0') {
        firstNonZeroIndex++;
    }

    // Calculate the position to round to
    const precision = firstNonZeroIndex - decimalIndex + 1;

    return parseFloat(value.toFixed(precision));
} else {
    return value; // If the value is 0 or negative, just return it
}
}

export function convertVolumeUnit(product: MultipleProductInfo) {
  let {volume, unit} = product
  if(MEAT_CATEGORIES.includes(product.category)) {
    if(product.unit == VolumeUnits.g) {
      volume  /= 1000
      unit = VolumeUnits.kg
    }
  }
  return {volume, unit}
}

export function getProductVolume(product: MultipleProductInfo) {
  const { volume, unit } = convertVolumeUnit(product)
  let cardVolume = `${volume}${unit}`
  if (product.multipleCount > 1) {
    cardVolume = `${volume}${unit}*${product.multipleCount} = ${volume * product.multipleCount}${unit}`
  }
  return cardVolume
}

export function getProductPrice(product: MultipleProductInfo, count: number) {
  const unitPrice = (product.multipleCount*count)%product.promotion_count == 0 ? product.promotion_price : product.price
  return Number((Number(unitPrice * product.multipleCount) *count).toFixed(2))
}