import { roundNonZero } from "./components/utils"
import { CategoryMapping } from "./pages"
import { CartProductInfo, SearchState } from "./types"

export function caclulateSavings(productValue: number, averageValue: number, productVolume: number, multipleCount: number) {
    const valueSavings = Math.abs(productValue - averageValue)
    let productSavings = valueSavings * productVolume * multipleCount
    if (productValue > averageValue) productSavings *= -1
    return { valueSavings, productSavings }
}

export function getSavingsString(product: CartProductInfo) {
    const { multipleCount, category_volume, valueSavings, count, productSavings } = product
    const productSign = productSavings > 0 ? "" : "-"
    let savingsString = ''
    if (multipleCount > 1) {
        savingsString = count > 1 ? `(${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${multipleCount})*${count} = ${productSign}R${Math.abs(productSavings * count).toFixed(2)}` :
            `${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${multipleCount} = ${productSign}R${Math.abs(productSavings * count).toFixed(2)}`
    } else {
        savingsString = count > 1 ? `${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${count}= ${productSign}R${Math.abs(productSavings * count).toFixed(2)}`
            : `${productSign}R${roundNonZero(valueSavings)}*${category_volume} = ${productSign}R${Math.abs(productSavings).toFixed(2)}`
    }
    return savingsString
}

export function getSubCategories(categoryMapping: CategoryMapping, category: string): {[key: string]: true} {
    const subCategoryKeys = Object.keys(categoryMapping[category]?.sub_categories ?? {})
    const subCategories = subCategoryKeys.reduce((subCat, key) => ({ ...subCat, [key]: true }), {})
    return subCategories
}

export function getBrandsCategory(categoryMapping: CategoryMapping, category: string, searchState: SearchState) {
    let brands = {}
    const subCategoryKeys = Object.keys(categoryMapping[category].sub_categories)
    for (const subCat of subCategoryKeys) {
        for (const store of Object.keys(searchState.store).filter(key => searchState.store[key])) {
            const brandKeys = categoryMapping[category].sub_categories[subCat][store]
            brands = { ...brands, ...brandKeys }
        }
    }
    return brands
}

export function getBrandsForSubCategory(categoryMapping: CategoryMapping, subCategories: string[], searchState: SearchState) {
    let brands = {}
    for (const subCat of subCategories) {
        for (const store of Object.keys(searchState.store).filter(key => searchState.store[key])) {
            const brandKeys = categoryMapping[searchState.category].sub_categories[subCat][store]
            brands = { ...brands, ...brandKeys }
        }
    }
    return brands
}

export function getAlert(searchState: SearchState) {
    if(!Object.keys(searchState.store).filter(store => searchState.store[store]).length) {
        return 'Select at least one store'
    } else if (searchState.category == '') {
        return 'Select at least one category'
    } else if(!Object.keys(searchState.subCategories).filter(subCat => searchState.subCategories[subCat]).length) {
        return 'Select at leat one sub-category'
    } else {
        return 'Select at least one brand'
    }
}