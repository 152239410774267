import { useState } from "react";
import { JobType, MultipleProductInfo, ProductInfo, RankingDetails, SearchState, SelectionState } from "../types";
import "./ProductCard.css";
import { Icon } from "@blueprintjs/core";
import { Badge, IconButton } from "@chakra-ui/react";
import { useCartContext, useSelectionContext } from "../hooks";
import { imageWidths, storeIcon } from "./resources";
import { convertVolumeUnit, filterTitle, getProductPrice, getProductVolume, roundNonZero } from "./utils";
import { BellIcon } from "@chakra-ui/icons";
import axios from "axios";
import { BACKEND_URL, MEAT_CATEGORIES, VALUE_UNITS } from "../resources";
import { caclulateSavings } from "../utils";

export function ProductCard({
  product,
  searchState,
  rankingDetails
}: {
  product: MultipleProductInfo;
  searchState: SearchState;
  rankingDetails: RankingDetails;
}) {
  const [count, setCount] = useState(1);
  const { cartState, setCartState } = useCartContext();
  const { selectionState, setSelectionState } = useSelectionContext();
  const [isProductFlagged, setIsProductFlagged] = useState(product.is_flagged)
  const { bestValueProductId, averageValueAmount } = rankingDetails
  const { volume, unit } = convertVolumeUnit(product)
  const { category_volume: categoryVolume, category, multipleCount, price, category_unit, multipleId, store, title, id, brand, image_url: imageUrl } = product

  console.log(product)

  function handleAddProduct() {
    setCartState((prev) => ({
      ...prev,
      [multipleId]: {
        ...product,
        count,
        valueSavings: valueSavings,
        productSavings: productSavings,
        isValueChoice: isValueProduct
      },
    }));
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      [multipleId]: searchState,
    }));
  }

  function handleRemoveProduct() {
    const newCartState = { ...cartState };
    const newSelectionState = { ...selectionState };
    delete selectionState[multipleId];
    delete newCartState[multipleId];
    setCartState(newCartState);
    setSelectionState(newSelectionState);
  }

  async function handleFlagProduct() {
    setIsProductFlagged(true)
    const payload = {
      jobType: JobType.FLAG_PRODUCT,
      params: {
        id: id
      },
    };
    await axios.post(BACKEND_URL + '/job/initiate', payload);
    return
  }

  const productPrice = getProductPrice(product, count)
  let productValue = roundNonZero(productPrice / (categoryVolume*count*multipleCount))
  let valueString = `(R${productValue.toFixed(2)}/${category_unit})`
  if (VALUE_UNITS.includes(category_unit)) {
    productValue = productPrice*100 / (categoryVolume*count*multipleCount)
    valueString = `(R${productValue.toFixed(2)}/100${category_unit})`
  }
  if (MEAT_CATEGORIES.includes(category)) {
    productValue = productPrice / (volume*count*multipleCount)
    valueString = `(R${productValue.toFixed(2)}/kg)`
  }

  const isValueProduct = bestValueProductId === multipleId
  const productValueDiff = ((productPrice / (categoryVolume*count*multipleCount)) - averageValueAmount) * 100 / averageValueAmount
  const productValueSign = productValueDiff > 0 ? "+" : '-'
  const productValueString = productValueDiff != 0 ? `${productValueSign}${Math.abs(productValueDiff).toFixed(0)}%` : ''
  const { valueSavings, productSavings } = caclulateSavings(price / categoryVolume, averageValueAmount, categoryVolume, multipleCount)

  const cardTitle = filterTitle(product).split(' x')[0]
  const cardVolume = getProductVolume(product)
  const isPromotionItem = !!product.promotion_id && !!product.promotion_count
  const isPromotionValid = (product.multipleCount*count)%product.promotion_count == 0
  const promotionText = `${product.promotion_count} for R${(product.promotion_count * product.promotion_price).toFixed(2)}`


  return (
    <div className="card_container">
      {cartState[multipleId] && <div className="card_overlay"></div>}
      <div className="card_container_top">
        <span className="card_container_store_chips">
          <img src={storeIcon[store]} width={50} alt={"check"} />
          <span className="card_chips">
            {isPromotionItem && <Badge
              colorScheme={isPromotionValid ? 'red': 'gray'}
              paddingLeft={"8px"}
              paddingRight={"8px"}
              borderRadius={"16px"}
              alignContent={'center'}
              paddingBottom={"2px"}
            >
              <text className="card_price_chip">{promotionText}</text>
            </Badge>}
            {isValueProduct &&
              <>
                <Badge
                  colorScheme='blue'
                  paddingLeft={"8px"}
                  paddingRight={"8px"}
                  borderRadius={"16px"}
                  alignContent={'center'}
                  paddingBottom={"2px"}
                >
                  <text className="card_price_chip">Value</text>
                </Badge></>
            }
            <IconButton onClick={handleFlagProduct} variant='outline' aria-label="flag" size='xs' icon={<BellIcon color={'inherit'} />} colorScheme={isProductFlagged ? 'red' : 'gray'} color={isProductFlagged ? '#b53b3b' : '#b8b7b7'} />
          </span>
        </span>
        <text className="card_title">{cardTitle} <text className="card_title_highlight">{multipleCount > 1 ? `[X${multipleCount}]` : ''}</text></text>
        <div className="card_container_middle">
          <div className="card_container_left">
            <text className="card_brand">{brand}</text>
            <text className="card_volume">
              {cardVolume}
            </text>
            <span className="card_value">
              <text className="card_volume">{valueString}</text>
              <text className="card_volume" style={{ color: productValueDiff < 0 ? '#3bb56c' : '#b53b3b' }}>{productValueString}</text>
            </span>
            <text className="card_price" style={{color: isPromotionValid ? '#b53b3b': "inherit"}}>
              <Icon icon="tag" color={isPromotionValid ? '#b53b3b': "#666666"} size={10} />R
              {productPrice}
            </text>
          </div>
          <div className="card_container_right">
            <img
              src={imageUrl}
              alt={title}
              width={imageWidths[store]}
            />
          </div>
        </div>
      </div>
      <div className="card_container_bottom">
        <span className="card_unit_count_container">
          <span className="unit_icon">
            {" "}
            <Icon
              icon="minus"
              color="white"
              size={14}
              onClick={() => setCount(count - 1 <= 1 ? 1 : count - 1)}
            />
          </span>
          <div className="card_unit_count">{count}</div>
          <span className="unit_icon">
            <Icon
              icon="plus"
              color="white"
              size={14}
              onClick={() => setCount(count + 1)}
            />
          </span>
        </span>
        {cartState[multipleId] ? (
          <button className="card_remove_button" onClick={handleRemoveProduct}>
            Remove
          </button>
        ) : (
          <button className="card_add_button" onClick={handleAddProduct}>
            Add
          </button>
        )}
      </div>
    </div>
  );
}
