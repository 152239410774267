import React, { useEffect, useState } from "react";
import "./Selector.css"; // Import CSS file
import { JobType, SearchState } from "../types";
import axios from "axios";
import {storeListIcon } from "./resources";
import { BACKEND_URL } from "../resources";

interface ProductsResponse {
  products?: any[];
  error?: string;
}

export function SearchSelector({
  setSearchState,
  clearTrigger
}: {
  setSearchState: (newState: Partial<SearchState>) => void;
  clearTrigger: boolean
}) {
  const [nameSearch, setNameSearch] = useState("");
  const [selected, setSelected] = useState(false);
  const [isFocus, setIsFocus] = useState(false)
  const [nameSearchResults, setNameSearchResults] = useState<any[]>([]);
  const [nameSearchLoading, setNameSearchLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timeout, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

  const fetchProductsByName = async () => {
    const payload = {
      jobType: JobType.NAME_SEARCH,
      params: {
        name: nameSearch,
      },
    };
    const res = await axios.post<ProductsResponse>(
      BACKEND_URL + '/job/initiate',
      payload
    );
    if (res.data.products) setNameSearchResults(res.data.products);
    setNameSearchLoading(false);
  };

  useEffect(() => {
    if (selected) {
      setSelected(false);
      return
    }
    if (timeout) clearTimeout(timeout);
    if (nameSearch.length === 0) {
      setNameSearchLoading(false);
      return;
    }
    const newTimeout = setTimeout(() => fetchProductsByName(), 2000);
    setNameSearchLoading(true);
    setTimeoutRef(newTimeout);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [nameSearch]);

  useEffect(() => {
    setNameSearch("")
  }, [clearTrigger])

  const handleFocus = () => {
    setIsOpen(true)
    setIsFocus(true)
  }

  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 5)
    setIsFocus(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearch(e.target.value);
  };

  const handleClear = () => {
    setNameSearch('')
    setSearchState({id: null})
  }

  const handleClick = (item: any) => {
    const { barcode, category, sub_category: subCategory, brand, title, category_volume } = item;
    let update: any = {
      barcode,
      category,
      subCategories: {[subCategory]: true},
      brands: {[brand]: true},
      minVolume: category_volume,
      maxVolume: category_volume
    }
    setSearchState(update);
    setSelected(true);
    setNameSearch(title);
    setIsOpen(false);
  };

  return (
    <div className="selector_container">
      <span className="input_clear_span">
        <input
          type="text"
          placeholder={"Search by title"}
          value={nameSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          className={nameSearch.length ? "input" : "input_empty"}
        />
        <div className={isFocus ? "input_clear_focus" : "input_clear"} onClick={handleClear}>{nameSearch.length ? 'X' : ''}</div>
      </span>
      {!nameSearchLoading &&
        isOpen &&
        (nameSearchResults.length > 0 && nameSearch.length > 0 ? (
          <div
            className="menu_list"
          >
            {nameSearchResults.map((item, i) => (
              <div
                key={item}
                onClick={() => handleClick(item)}
                className={"menu_item"}
              >
                <div className="name_search_item">
                  <p> {item.title}</p>
                  <img
                  src={storeListIcon[item.store]}
                  alt={item.title}
                />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className="menu_list"
          >
            <div
              key={"none"}
              className={"menu_item"}
            >
              No result found
            </div>
          </div>
        ))}
      {nameSearchLoading && isOpen && (
        <div
          className="menu_list"
        >
          <div
            key={"none"}
            className={"menu_item"}
          >
            Searching...
          </div>
        </div>
      )}
    </div>
  );
}
