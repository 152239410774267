import React, { useEffect, useRef, useState } from 'react';
import './Selector.css'
import { Icon } from "@chakra-ui/react"
import { BiSelectMultiple } from "react-icons/bi";
import { MdClear } from "react-icons/md";

type MultiSelectorProps = {
  placeholder: string;
  listItems: string[];
  selectedValues: { [key: string]: true };
  handleSelectionChange: (update: string) => void;
  handleSelectionClear: () => void;
  handleSelectAll: () => void;
};

export function MultiSelector({
  placeholder,
  listItems,
  handleSelectionChange,
  handleSelectionClear,
  handleSelectAll,
  selectedValues,
}: MultiSelectorProps) {
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<string[]>(listItems);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Filter items based on input value
  useEffect(() => {
    if (inputValue) {
      setFilteredItems(
        listItems.filter(item =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      setFilteredItems(listItems);
    }
  }, [inputValue, listItems]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsFocus(false);
    }
  };

  useEffect(() => {
    if (isFocus) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocus]);

  const handleClick = (item: string) => {
    handleSelectionChange(item);
    // setInputValue(''); // Clear input after selection
  };

  const handleSelectAllItems = () => {
    if( selectedValues['blank'] == true) {
      return
    }
    if (selectedItems < 1) {
      handleSelectAll()
    } else {
      handleClear()
    }
  }
  const handleClear = () => {
    handleSelectionClear();
    setInputValue('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  let value = inputValue
  const selectedItems = Object.keys(selectedValues).length
  if (!isFocus) {
    value = Object.keys(selectedValues).join(' | ').replace('blank', 'N/A')
    if (value.length > 35) {
      value = `${selectedItems} selected`
    }
  }

  const sortedItems = filteredItems.sort()
  let icon = <></>
  if (!isFocus && selectedItems > 0) {
    icon = <Icon fontSize="xl">
      <MdClear />
    </Icon>
  }
  if (isFocus) {
    icon = selectedItems < 1 ? <Icon fontSize="xl">
      <BiSelectMultiple />
    </Icon> : <Icon fontSize="xl">
      <MdClear />
    </Icon>
  }
  return (
    <div className='selector_container' ref={dropdownRef}>
      <span className="input_clear_span">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onClick={() => setIsFocus(true)}
          className={isFocus ? 'input_focus' : 'input'}
        />
        <div
          className={isFocus ? "input_clear_focus" : "input_clear"}
          onClick={handleSelectAllItems}
        >
          {icon}
        </div>
      </span>
      {isFocus && (
        <div className="menu_list">
          {filteredItems.length > 0 ? (
            sortedItems.map((item) => (
              <div
                key={item}
                onClick={() => handleClick(item)}
                className={selectedValues[item] ? "menu_item_selected" : "menu_item"}
              >
                {item}
              </div>
            ))
          ) : (
            <div className="menu_item">No matches found</div>
          )}
        </div>
      )}
    </div>
  );
}
